let url = "";

if (process.env.NODE_ENV === "development") {
    url = "http://localhost:8000";
} else {
    url = "https://api.linkash.com.br";
}

export const Constants = {
    url: url,
    baseUrl: url + "/api/admin",
};

export const linkashTheme = {
    color: {
        principal: {
            100: "#E7F2FF",
            800: "#0E396C",
            900: "#082D58",
        },
        green: {
            100: "#BBEAD9",
            500: "#2CA57A"
        },
        gray: {
            subtle: "#F5F7F9",
            100: "#E7EBF0",
            200: "#CBD3DC",
            500: "#747D88",
            600: "#5A636E",
            700: "#373E46",
            900: "#14181D"
        },
        orange: {
            100: "#FFE3C3",
        },
        red: {
            100: "#FFD8D6",
            500: "#CB3939"
        },
        white: "#FFF"
    },
}

export const enumAntecipacaoStatus = {
    CREATED: "CREATED",
    PENDING_SIGNATURE: "PENDING_SIGNATURE",
    SIGNED: "SIGNED",
    IN_EXCHANGE_FOR_OWNERSHIP: "IN_EXCHANGE_FOR_OWNERSHIP",
    CHANGE_OF_OWNERSHIP_DENIED: "CHANGE_OF_OWNERSHIP_DENIED",
    CHANGE_OF_OWNERSHIP_ACCEPTED: "CHANGE_OF_OWNERSHIP_ACCEPTED",
    PENDING_DEPOSIT: "PENDING_DEPOSIT",
    DEPOSIT_DENIED: "DEPOSIT_DENIED",
    DEPOSIT_ACCEPTED: "DEPOSIT_ACCEPTED",
    FINALIZED: "FINALIZED",
}

export const antecipacaoStatus = [
    { value: enumAntecipacaoStatus.CREATED, label: "Antecipação solicitada" },
    { value: enumAntecipacaoStatus.PENDING_SIGNATURE, label: "Assinatura de contrato pendente" },
    { value: enumAntecipacaoStatus.SIGNED, label: "Contrato assinado" },
    { value: enumAntecipacaoStatus.IN_EXCHANGE_FOR_OWNERSHIP, label: "Em troca de titularidade" },
    { value: enumAntecipacaoStatus.CHANGE_OF_OWNERSHIP_DENIED, label: "Troca de titularidade negada" },
    { value: enumAntecipacaoStatus.CHANGE_OF_OWNERSHIP_ACCEPTED, label: "Troca de titularidade aceita" },
    { value: enumAntecipacaoStatus.PENDING_DEPOSIT, label: "Em aprovação de depósito" },
    { value: enumAntecipacaoStatus.DEPOSIT_DENIED, label: "Depósito negado" },
    { value: enumAntecipacaoStatus.DEPOSIT_ACCEPTED, label: "Depósito aceito" },
    { value: enumAntecipacaoStatus.FINALIZED, label: "Antecipação finalizada" },
];