import React, { Component, useState } from 'react';
import { Alert } from "react-bootstrap";

export default class BetterAlerts extends Component {
    constructor() {
        super();

        this.state = {
            show: true
        };
    }

    render() {
        return this.state.show ? (
            <div>
                {this.props.errors ?
                    this.props.errors.map((obj, i) => {
                        return <Alert key={i} variant="danger" onClose={() => this.setState({ show: false })} dismissible>{obj}</Alert>
                    })
                    : null
                }

                {this.props.success ?
                    this.props.success.map((obj, i) => {
                        return <Alert key={i} variant="success" onClose={() => this.setState({ show: false })} dismissible>{obj}</Alert>
                    })
                    : null
                }
            </div>
        ) : <></>;
    }
}