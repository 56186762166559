export default {
    header: {
        self: {},
        items: [],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
            },
            {
                title: "Antecipações",
                root: true,
                bullet: "dot",
                icon: "fas fa-file-alt",
                submenu: [
                    {
                        title: "Antecipações",
                        page: "antecipacao",
                    },
                    {
                        title: "Aprovações de depósito",
                        page: "aprovacao-deposito",
                    },
                ],
            },
            {
                title: "Lojista",
                root: true,
                icon: "fas fa-store",
                page: "empresa",
                bullet: "dot",
                submenu: [
                    {
                        title: "Lojistas",
                        page: "lojista",
                    },
                    {
                        title: "Empr. pré-autorizadas",
                        page: "pre-autorizado",
                    },
                ],
            },
            {
                title: "Relatórios",
                root: true,
                icon: "fas fa-chart-pie",
                page: "empresa",
                bullet: "dot",
                submenu: [
                    {
                        title: "Antecipações finalizadas",
                        page: "relatorio/relatorio01",
                    },
                    {
                        title: "Antecipações negadas",
                        page: "relatorio/relatorio02",
                    },
                ],
            },
            {
                title: "Acessos",
                root: true,
                bullet: "dot",
                icon: "fas fa-users",
                submenu: [
                    {
                        title: "Administradores",
                        page: "admin",
                    },
                    {
                        title: "Grupos de permissões",
                        page: "grupo",
                    },
                ],
            },
        ],
    },
};
