import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "./dashboard/DashboardPage";
import { LayoutSplashScreen } from "../../../_metronic";

const AntecipacaoPage = lazy(() => import("./antecipacao/AntecipacaoPage"));
const AprovacaoDepositoPage = lazy(() => import("./aprovacao-deposito/AprovacaoDepositoPage"));
const LojistaPage = lazy(() => import("./lojista/LojistaPage"));
const PreAutorizadoPage = lazy(() => import("./pre-autorizado/PreAutorizadoPage"));
const RelatorioPage = lazy(() => import("./relatorio/RelatorioPage"));
const AdminPage = lazy(() => import("./admin/AdminPage"));
const GrupoPage = lazy(() => import("./grupo/GrupoPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/dashboard" />

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/antecipacao" component={AntecipacaoPage} />
                <Route path="/aprovacao-deposito" component={AprovacaoDepositoPage} />
                <Route path="/lojista" component={LojistaPage} />
                <Route path="/pre-autorizado" component={PreAutorizadoPage} />
                <Route path="/relatorio" component={RelatorioPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/grupo" component={GrupoPage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
