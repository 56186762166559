import axios from "axios";

export const createFormikValidation = (formMap, values) => {
    const errors = {};

    for (let [key, value] of formMap) {
        if (!values[key] && values[key] !== 0) {
            errors[key] = "O campo " + value + " é obrigatório.";
        } else if (key.includes("email") && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[key])) {
            errors[key] = "Você precisa informar um e-mail válido.";
        } else if ((key.includes("senha") || key.includes("password")) && values[key].length < 6) {
            errors[key] = "A senha deve ter pelo menos 6 caracteres.";
        } else if (key.includes("cep") && values[key].length < 5) {
            errors[key] = "Utilize no mínimo 8 caracteres.";
        } else if (key.includes("cpf") && !validaCpf(values[key])) {
            errors[key] = "O CPF informado é inválido.";
        } else if (key.includes("cnpj") && !validaCnpj(values[key])) {
            errors[key] = "O CNPJ informado é inválido.";
        } else if (key.includes("telefone") && values[key].length < 14) {
            errors[key] = "Utilize no mínimo 10 números.";
        }
    }

    return errors;
};

export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

export const formatBRLInput = (input) => {
    input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, "")) / 10 ** 2;

    input.target.value = formatBRL(input.target.value);

    return input;
};

export const formatBRL = (value) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
    });

    value = formatter.format(value);

    return value;
};

export const formatHourInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1:$2");

    input.target.value = value;

    return input;
};

export const formatCepInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatCPFInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatDataInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");

    input.target.value = value;

    return input;
};

export const formatIntegerInput = (input) => {
    input.target.value = Number(input.target.value.toString().replace(/[^0-9]/g, ""));

    if (parseInt(input.target.value) === 0) input.target.value = "";

    return input;
};

export const formatTelefoneInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{0})(\d)/, "$1($2");
    value = value.replace(/(\d{2})(\d)/, "$1) $2");
    value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
    if (input.target.value.length > 8) {
        var cep = input.target.value.replace(/[^0-9]/, "");

        const instance = axios.create();

        instance
            .get(`https://viacep.com.br/ws/${cep}/json`, {})
            .then((res) => {
                if (res.data.erro) {
                    setFieldValue(nomeEndereco, "");
                    setFieldValue(nomeBairro, "");
                    setFieldValue(nomeCidade, "");
                    setFieldValue(nomeUf, "AC");
                } else {
                    setFieldValue(nomeEndereco, res.data.logradouro);
                    setFieldValue(nomeBairro, res.data.bairro);
                    setFieldValue(nomeCidade, res.data.localidade);
                    setFieldValue(nomeUf, res.data.uf);
                }
            })
            .catch((err) => { });
    }

    return formatCepInput(input);
};

export const listaUfs = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];


/* eslint-disable */
export const validaCpf = (cpf) => {
    var add = 0;
    var rev = 0;

    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf == "") return false;

    if (cpf.length != 11) return false;

    for (var i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) {
        rev = 0;
    }
    if (rev != parseInt(cpf.charAt(9))) {
        return false;
    }

    add = 0;

    for (var j = 0; j < 10; j++) {
        add += parseInt(cpf.charAt(j)) * (11 - j);
    }

    rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) {
        rev = 0;
    }

    if (rev != parseInt(cpf.charAt(10))) {
        return false;
    }

    return true;
};
/* eslint-enable */

export const validaCnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj.length !== 14) {
        return false;
    }

    if (/^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    let add = 0;
    let rev = 0;
    let pos = 5;

    for (let i = 0; i < 12; i++) {
        add += parseInt(cnpj.charAt(i)) * pos;
        pos = pos === 2 ? 9 : pos - 1;
    }

    rev = add % 11 < 2 ? 0 : 11 - (add % 11);

    if (rev !== parseInt(cnpj.charAt(12))) {
        return false;
    }

    add = 0;
    pos = 6;

    for (let i = 0; i < 13; i++) {
        add += parseInt(cnpj.charAt(i)) * pos;
        pos = pos === 2 ? 9 : pos - 1;
    }

    rev = add % 11 < 2 ? 0 : 11 - (add % 11);

    return rev === parseInt(cnpj.charAt(13));
};

export const formatCNPJInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const textoExclusao = {
    title: "Atenção!",
    text: "Tem certeza que deseja remover?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: "Não",
    confirmButtonText: "Sim",
};

export const limparMoeda = (valor) => {
    valor = valor.replaceAll("R$", "");
    valor = valor.replaceAll(".", "");
    valor = valor.replaceAll(",", ".");
    valor = valor.replaceAll("/^p{Z}+|p{Z}+$/u", "");
    valor = valor.trim();

    return valor;
};

export const validateFullName = (name) => {
    // Trim any leading/trailing spaces
    const trimmedName = name.trim();

    // Split the name by spaces (whitespace)
    const nameParts = trimmedName.split(/\s+/); // This splits by one or more spaces

    // Check if there are more than two parts and first & second are non-empty
    if (nameParts.length >= 2 && nameParts[0].length > 0 && nameParts[1].length > 0) {
        return true; // Valid first and last name
    }

    return false; // Invalid input
}