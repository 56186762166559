import React, { Component } from "react";
import { ArrowDropDown, ArrowDropUp, Remove } from '@material-ui/icons';
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { getDashboard } from "../../../crud/dashboard.crud";
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import Swal from "sweetalert2";
import { formatBRL } from "../../../utils/Utils";
import { linkashTheme } from "../../../utils/Constants";

export default class DashboardPage extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            data: null,
        };
    }

    componentDidMount = () => {
        this.setState({ loading: true });

        getDashboard()
            .then((res) => {
                if (res.data.errors) {
                    return Swal.fire("Ops!", res.data.errors[0], "error");
                }

                this.setState({ data: res.data, loading: false });
            })
            .catch(() => Swal.fire("Ops!", "Parece que houve um problema. Por favor, entre em contato com o suporte.", "error"));
    };

    render() {

        const Icon = ({ current, before }) => {
            if (current > before) {
                return <ArrowDropUp fontSize="large" style={{ color: linkashTheme.color.green[500] }} />
            } else if (before > current) {
                return <ArrowDropDown fontSize="large" style={{ color: linkashTheme.color.red[500] }} />
            } else {
                return <Remove fontSize="large" style={{ color: linkashTheme.color.gray[600] }} />
            }
        }

        return (
            <BetterCircularProgress loading={this.state.loading}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <Portlet className="kt-portlet--border-bottom-brand" style={{ borderBottom: `4px solid ${linkashTheme.color.principal[800]}` }} fluidHeight={true}>
                            <PortletBody>
                                <div className="kt-widget26">
                                    <div className="kt-widget26__content">
                                        <span className="kt-widget26__desc color-gray-600">Faturamento</span>

                                        <span className="kt-widget26__number color-gray-900">
                                            {this.state.data?.faturamento ? formatBRL(this.state.data.faturamento) : formatBRL(0)}
                                            {this.state.data?.faturamento_anterior && <Icon current={this.state.data?.faturamento} before={this.state.data?.faturamento_anterior} />}
                                        </span>
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <Portlet className="kt-portlet--border-bottom-brand" style={{ borderBottom: `4px solid ${linkashTheme.color.principal[800]}` }} fluidHeight={true}>
                            <PortletBody>
                                <div className="kt-widget26">
                                    <div className="kt-widget26__content">
                                        <span className="kt-widget26__desc color-gray-600">Valor total antecipado</span>

                                        <span className="kt-widget26__number color-gray-900">
                                            {this.state.data?.valor_total_antecipado ? formatBRL(this.state.data.valor_total_antecipado) : formatBRL(0)}
                                            {this.state.data?.valor_total_antecipado_anterior && <Icon current={this.state.data?.valor_total_antecipado} before={this.state.data?.valor_total_antecipado_anterior} />}
                                        </span>
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <Portlet className="kt-portlet--border-bottom-brand" style={{ borderBottom: `4px solid ${linkashTheme.color.principal[800]}` }} fluidHeight={true}>
                            <PortletBody>
                                <div className="kt-widget26">
                                    <div className="kt-widget26__content">
                                        <span className="kt-widget26__desc color-gray-600">Antecipações realizadas</span>

                                        <span className="kt-widget26__number color-gray-900">
                                            {this.state.data?.antecipacoes_realizadas ?? "Desconhecido"}
                                            {this.state.data?.antecipacoes_realizadas_anterior && <Icon current={this.state.data?.antecipacoes_realizadas} before={this.state.data?.antecipacoes_realizadas_anterior} />}
                                        </span>
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <Portlet className="kt-portlet--border-bottom-brand" style={{ borderBottom: `4px solid ${linkashTheme.color.principal[800]}` }} fluidHeight={true}>
                            <PortletBody>
                                <div className="kt-widget26">
                                    <div className="kt-widget26__content">
                                        <span className="kt-widget26__desc color-gray-600">Antecipações negadas</span>

                                        <span className="kt-widget26__number color-gray-900">
                                            {this.state.data?.antecipacoes_negadas ?? "Desconhecido"}
                                            {this.state.data?.antecipacoes_negadas_anterior && <Icon current={this.state.data?.antecipacoes_negadas} before={this.state.data?.antecipacoes_negadas_anterior} />}
                                        </span>
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </div>
                </div>
            </BetterCircularProgress>
        );
    }
}
